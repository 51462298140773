import React from 'react'
import {
  Toolbar,
  AppBar,
  Box,
  Container,
  styled,
  Button,
  Grid,
  Typography,
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import Google from '../../img/Google.svg'
import Apple from '../../img/AppStore.svg'
import Iphone from '../../img/DAD_Screens.png'
import Dad from '../../img/Logo.svg'
import Footer from '../Shared/Footer'

const BannerCaption = styled('div')(({ theme }) => ({
  width: '100%',
}))

const BannerTitle = styled(Typography)(({ theme }) => ({
  fontSize: '66px',
  lineHeight: '98px',
  fontWeight: 800,
  color: 'white',
  fontFamily: 'Lato !important',
  fontStyle: 'normal',

  [theme.breakpoints.down('lg')]: {
    fontSize: '54px',
    lineHeight: '76px',
    paddingRight: '6%',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '46px',
    lineHeight: '46px',
    paddingRight: '0%',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '26px',
    lineHeight: '30px',
    marginTop: '8%',
    paddingRight: 0,
  },
}))

const pages = [
  { title: 'About Us', url: '/aboutus', disable: false },
  { title: 'Contact Us', url: '/contactus', disable: false },
]

const Navbar = ({ user }) => {
  const navigate = useNavigate()

  const handleCloseNavMenu = (page) => {
    navigate(page.url)
  }

  return (
    <div className='landingpageclass'>
      <AppBar position='static' className='backgroundimage'>
        <Container maxWidth='xxl' className='navbar-active'>
          <Toolbar disableGutters>
            <Typography
              variant='h6'
              noWrap
              component='a'
              href='/'
              sx={{
                mr: 2,
                paddingLeft: {
                  // xl: "8% !important",
                  lg: '8% !important',
                  md: '2% !important',
                  sm: '0',
                },
                display: { md: 'flex' },
                fontFamily: 'Lato',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                marginTop: { xl: '10px', lg: '10px', md: '0px', sm: '20px' },
                paddingBottom: { xl: '10px', lg: '10px' },
                marginRight: { md: '0' },
              }}
            >
              <img
                src={Dad}
                alt='Landscape picture'
                width={'100px'}
                className='logo-style'
                height={'100px'}
              />
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'flex' },
                justifyContent: 'flex-end',
                marginRight: { sm: '0px', md: '90px' },
              }}
              paddingLeft={{
                xxl: '15%',
                xl: !user ? '20%' : '10%',
                lg: !user ? '10%' : '5%',
                md: !user ? '8%' : '5%',
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.title}
                  onClick={(e) => handleCloseNavMenu(page)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.title}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>

        <Container
          fixed
          style={{ maxWidth: '100%', padding: 0 }}
          component='main'
          className='banner-section'
          sx={{
            backgroundSize: 'cover',
          }}
        >
          <Grid
            container
            alignItems='center'
            flexDirection={{ xs: 'column', sm: 'row' }}
            textAlign={{ xs: 'center', sm: 'left' }}
            paddingLeft={{ xl: '165px', lg: '80px', md: '50px' }}
            paddingRight={{ lg: '0', md: '60px' }}
            paddingBottom={{ lg: '20px' }}
            marginTop={{ xs: '0px', md: '0', lg: '0', sm: '20px' }}
          >
            <Grid
              className='bannerText'
              item
              md={6}
              lg={6}
              sm={12}
              xs={12}
              textAlign={{ sm: 'center' }}
              paddingTop={{ lg: '20px', md: '40px' }}
              paddingBottom={{
                lg: 'auto',
                md: 'auto',
                xs: '0',
                sm: '0',
              }}
              paddingLeft={{ lg: '0', md: '0', sm: '60px' }}
              display={{ xs: 'block', md: 'none' }}
            >
              <BannerCaption>
                <BannerTitle variant='h1'>
                  <p
                    style={{
                      margin: '0px',
                      padding: '0px',
                      fontFamily: 'Heebo',
                    }}
                  >
                    The <span className='color-red'>Dates and Dreams</span> App!
                  </p>
                </BannerTitle>
              </BannerCaption>
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent='center'
              display={{ lg: 'none', md: 'none', sm: 'block', xs: 'block' }}
              sx={{ marginTop: { xs: '3%' } }}
              style={{ maxWidth: '90%', width: '100%' }}
              className='banner-photo'
            >
              <img src={Iphone} alt='First Logo' />
            </Grid>
            <Grid
              className='bannerText'
              item
              md={6}
              lg={6}
              sm={12}
              xs={12}
              paddingTop={{ lg: '20px', md: '40px' }}
              paddingBottom={{
                lg: 'auto',
                md: 'auto',
                xs: '0',
                sm: '0',
              }}
              paddingLeft={{ lg: '0', md: '0', sm: '0px' }}
            >
              <BannerCaption>
                <BannerTitle
                  variant='h1'
                  sx={{ display: { xs: 'none', md: 'block' } }}
                >
                  <p
                    className='banner-title-app'
                    style={{ marginBottom: '10px', fontFamily: 'Heebo' }}
                  >
                    The <span className='color-red'>Dates and Dreams</span> App!
                  </p>
                </BannerTitle>
                <Typography
                  maxWidth='897px'
                  sx={{
                    margin: `0 auto`,
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    textAlign: {
                      lg: 'left',
                      md: 'left',
                      sm: 'center',
                      xs: 'center',
                    },
                    fontSize: {
                      lg: '20px',
                      md: '18px',
                      sm: '18px',
                    },
                    lineHeight: {
                      lg: '28px',
                      md: '26px',
                      sm: '24px',
                    },
                    maxWidth: { lg: '80%', md: '85%', sm: '100%', xs: '100%' },
                    padding: 0,
                    margin: { lg: 0, md: 0, sm: 0, xs: 'auto' },
                    fontFamily: 'Heebo',
                  }}
                >
                  Spice up your love life with Dates and Dreams,<br></br> where
                  tradition meets passion!
                </Typography>
                <Grid
                  container
                  justifyContent={{
                    lg: 'left',
                    md: 'left',
                    sm: 'center',
                    xs: 'center',
                  }}
                  alignItems={{
                    lg: 'left',
                    md: 'baseline',
                    sm: 'center',
                    xs: 'center',
                  }}
                  display={{
                    lg: 'flex',
                    md: 'flex',
                    sm: 'flex',
                    xs: 'flex',
                  }}
                  flexDirection={{
                    sm: 'column',
                    xs: 'column',
                    lg: 'row',
                  }}
                  paddingTop='15px'
                  gap={'10px'}
                >
                  <Grid
                    alignItems={{ lg: 'baseline' }}
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                  >
                    <Link
                      to='https://play.google.com/store/apps/details?id=com.dateadesi.app'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img
                        src={Google}
                        alt='google'
                        width={'174px'}
                        height={'52px'}
                      />
                    </Link>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    md={4}
                    lg={4}
                    className='grid-root-apple'
                  >
                    <Link
                      to='https://apps.apple.com/us/app/date-a-desi/id6460647902'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img
                        src={Apple}
                        alt='apple'
                        width={'174px'}
                        height={'52px'}
                      />
                    </Link>
                  </Grid>
                </Grid>
              </BannerCaption>
            </Grid>
            <Grid
              item
              md={6}
              lg={4}
              sm={6}
              justifyContent='center'
              display={{ lg: 'flex', md: 'flex', sm: 'none', xs: 'none' }}
              sx={{
                marginTop: { xl: '2%', lg: '3%', md: '5%', sm: '0' },
                marginBottom: '10px',
              }}
              className='banner-photo'
            >
              <img
                src={Iphone}
                alt='First Logo'
                // style={{
                //   height: '610px',
                //   width: '557px',
                // }}
              />
            </Grid>
          </Grid>
        </Container>
      </AppBar>
      <Footer />
    </div>
  )
}

export default Navbar
