import { Box, Button, Container, Toolbar, Typography } from '@mui/material'
import React from 'react'
import './aboutus.css'
import Dad from '../../img/Logo.svg'
import { useNavigate } from 'react-router'
import First from '../../img/first.png'
import Second from '../../img/second.png'
import Third from '../../img/third.png'
import Footer from '../Shared/Footer'

export const AboutUs = () => {
  const navigate = useNavigate()

  const pages = [
    { title: 'About Us', url: '/aboutus', disable: false },
    { title: 'Contact Us', url: '/contactus', disable: false },
  ]

  const handleCloseNavMenu = (page) => {
    navigate(page.url)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div className='aboutus'>
        <Container style={{ flex: 1 }} maxWidth='xxl' className='navbar-active'>
          <Toolbar disableGutters>
            <Typography
              variant='h6'
              noWrap
              component='a'
              href='/'
              sx={{
                mr: 2,
                paddingLeft: {
                  // xl: "8% !important",
                  lg: '8% !important',
                  md: '2% !important',
                  sm: '0',
                },
                display: { md: 'flex' },
                fontFamily: 'Lato',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                marginTop: { xl: '10px', lg: '10px', md: '0px', sm: '20px' },
                paddingBottom: { xl: '10px', lg: '10px' },
                marginRight: { md: '0' },
              }}
            >
              <img
                src={Dad}
                alt='Landscape picture'
                width={'100px'}
                style={{ marginTop: '10px' }}
                className='logo-style'
                height={'100px'}
              />
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'flex' },
                justifyContent: 'flex-end',
                marginRight: { sm: '0px', md: '90px' },
              }}
              paddingLeft={{
                xxl: '15%',
                xl: '20%',
                lg: '10%',
                md: '8%',
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.title}
                  onClick={(e) => handleCloseNavMenu(page)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.title}
                </Button>
              ))}
            </Box>
          </Toolbar>
          <Box>
            <Typography
              variant='h3'
              style={{ color: 'white', textAlign: 'center' }}
            >
              About Us
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              margin: '50px 0px 90px 0px',
              justifyContent: 'center',
              gap: '70px',
              alignItems: 'center',
              flexWrap: 'wrap',
              fontFamily: 'Heebo',
            }}
          >
            <Box>
              <img
                src={First}
                style={{ borderRadius: '50%', objectFit: 'cover' }}
              />
              <Typography
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                  color: 'white',
                }}
                variant='h5'
              >
                Generate Excitement
              </Typography>
            </Box>
            <Box>
              <img
                src={Second}
                style={{ borderRadius: '50%', objectFit: 'cover' }}
              />
              <Typography
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                  color: 'white',
                }}
                variant='h5'
              >
                Grab Interest
              </Typography>
            </Box>
            <Box>
              <img
                src={Third}
                style={{ borderRadius: '50%', objectFit: 'cover' }}
              />
              <Typography
                variant='h5'
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                  color: 'white',
                }}
              >
                Feature Something
              </Typography>
            </Box>
          </Box>
        </Container>
      </div>
      <Footer />
    </div>
  )
}
