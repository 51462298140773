import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import Dad from '../../img/Logo.svg'
import Footer from '../Shared/Footer'
import './contactus.css'

export const ContactUs = () => {
  const navigate = useNavigate()
  const [submit, setSubmit] = useState(false)
  const [loading, setLoading] = useState(false)

  const pages = [
    { title: 'About Us', url: '/aboutus', disable: false },
    { title: 'Contact Us', url: '/contactus', disable: false },
  ]

  const handleCloseNavMenu = (page) => {
    navigate(page.url)
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { email: '', name: '', subject: '', description: '' },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Email must be valid email')
        .required('Email required'),
      name: yup.string().required('Name required'),
      subject: yup.string().required('Subject required'),
      description: yup.string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      // await mutateAsync(values)
      // await fetchUser()
      setLoading(true)
      let data = await axios.post(
        'https://api.dateadesi.com/api/v1/cms/contact-us',
        values
      )
      if (data) {
        console.log('inside this')
        resetForm()
        setLoading(false)
        toast.success(
          'Thank You for Reaching Out! Your Form has Been Submitted.'
        )
      }
    },
  })

  return (
    <>
      <div className='contactus'>
        <Container style={{ flex: 1 }} maxWidth='xxl' className='navbar-active'>
          <Toolbar disableGutters>
            <Typography
              variant='h6'
              noWrap
              component='a'
              href='/'
              sx={{
                mr: 2,
                paddingLeft: {
                  // xl: "8% !important",
                  lg: '8% !important',
                  md: '2% !important',
                  sm: '0',
                },
                display: { md: 'flex' },
                fontFamily: 'Lato',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                marginTop: { xl: '10px', lg: '10px', md: '0px', sm: '20px' },
                paddingBottom: { xl: '10px', lg: '10px' },
                marginRight: { md: '0' },
              }}
            >
              <img
                src={Dad}
                alt='Landscape picture'
                width={'100px'}
                style={{ marginTop: '10px' }}
                className='logo-style'
                height={'100px'}
              />
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'flex' },
                justifyContent: 'flex-end',
                marginRight: { sm: '0px', md: '90px' },
              }}
              paddingLeft={{
                xxl: '15%',
                xl: '20%',
                lg: '10%',
                md: '8%',
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page.title}
                  onClick={(e) => handleCloseNavMenu(page)}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                >
                  {page.title}
                </Button>
              ))}
            </Box>
          </Toolbar>
          <Box>
            <Typography
              variant='h3'
              style={{ color: 'white', textAlign: 'center' }}
            >
              Contact Us
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 'auto ',
                backgroundColor: 'white',
                width: { lg: '70%', md: '70%' },
                padding: '20px 40px',
                margin: '20px auto',
                borderRadius: '12px',
                gap: '20px',
              }}
            >
              <Typography>Connect with Us – We're Here to Help!</Typography>
              <FormControl>
                <FormLabel style={{ marginBottom: '4px' }}>Name</FormLabel>
                <TextField
                  id='name'
                  name='name'
                  value={formik.values.name}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  error={!!(formik.touched.name && formik.errors?.name)}
                  placeholder='Enter Name'
                  onChange={formik.handleChange}
                />
                {formik.touched?.name && formik.errors?.name && (
                  <div style={{ color: 'red' }}>{formik.errors?.name}</div>
                )}
              </FormControl>
              <FormControl>
                <FormLabel style={{ marginBottom: '4px' }}>Email</FormLabel>
                <TextField
                  id='email'
                  name='email'
                  value={formik.values.email}
                  error={formik.touched?.email && formik.errors.email}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  onChange={formik.handleChange}
                  placeholder='Enter Email'
                />
                {formik.touched.email && formik.errors.email && (
                  <div style={{ color: 'red' }}>{formik.errors?.email}</div>
                )}
              </FormControl>
              <FormControl>
                <FormLabel style={{ marginBottom: '4px' }}>Subject</FormLabel>
                <TextField
                  id='subject'
                  name='subject'
                  value={formik.values.subject}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  error={formik.touched.subject && formik.errors.subject}
                  placeholder='Enter Subject'
                  onChange={formik.handleChange}
                />
                {formik.touched.subject && formik.errors.subject && (
                  <div style={{ color: 'red' }}>{formik.errors.subject}</div>
                )}
              </FormControl>
              <FormControl>
                <FormLabel style={{ marginBottom: '4px' }}>
                  Description
                </FormLabel>
                <TextField
                  id='description'
                  name='description'
                  value={formik.values.description}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: '#6F7E8C',
                      },
                    },
                  }}
                  error={
                    formik.touched.description && formik.errors.description
                  }
                  placeholder='Enter the Description'
                  rows={3}
                  onChange={formik.handleChange}
                  multiline
                />
                {formik.touched.description && formik.errors.description && (
                  <div style={{ color: 'red' }}>
                    {formik.errors.description}
                  </div>
                )}
              </FormControl>
              <Button
                variant='contained'
                type='submit'
                sx={{
                  width: 'fit-content',
                  backgroundColor: '#6F7E8C',
                  ':hover': {
                    backgroundColor: '#6F7E8C',
                  },
                }}
              >
                {loading ? (
                  <CircularProgress size={'25px'} style={{ color: 'white' }} />
                ) : (
                  'Submit'
                )}
              </Button>
            </Box>
          </form>
        </Container>
      </div>
      <Footer />
    </>
  )
}
